.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1faee;
}

.OuterContainer {
  width: 90%;
  padding: 6em 0em 2em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.AppHeaderContainer {
  width: 100%;
  display: flex;
  border-bottom: 1px solid black;
  justify-content: center;
  position: fixed;
  background-color: #a8dadc;
}

.AppHeader {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  padding: .5em 0em;
}

.IconContainer {
  display: flex;
  justify-content: space-between;
}

#BannerPhoto {
  border-radius: 50%;
  max-height: 3em;
}

#CareRingPic {
  width: auto;
}

#ProfilePhoto {
  border-radius: 50%;
  max-height: 15em;
}

#GHIcon {
  margin: 0 0 0 2em;
}

.Project {
  display: flex;
  justify-content: space-around;
}

.ProjectMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Reversed {
  flex-direction: row-reverse;
}

.ReversedMobile {
  flex-direction: column;
}

.Bubbled {
  background-color: #a8dadc;
  padding: 2em 0em;
  border-radius: 1em;
}

.BubbledMobile{
  background-color: #a8dadc;
  padding: 0;
  border-radius: 1em;
}

.ProjectContent {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.ProjectContentMobile {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em 0;
}

.ProjectsContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

h2 {
  padding: 2em 0 0;
}

footer {
  padding: 0 0 2em;
}
